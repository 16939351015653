<div [ngSwitch]="template">
    <div *ngSwitchCase="'default'" class="valor default {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="valor-number" *ngIf="valor > 0">
        <div class="content tariff" *ngIf="!servico.promocao">
          <div class="box">
            <span class="a-partir">a partir de</span>
          </div>
          <div class="box box-valor">
            <span [style.color]="color" class="main" itemprop="price">R$ {{valor1}},{{valor2}}</span> 
          </div>
        </div>  
        <div class="content _promocao" *ngIf="servico.promocao"> 
          <div class="box tariff">
            <div class="valor-promocao">
              <span>De <strong>R$ {{valor}}</strong> por</span><br>
            </div>
            <div class="box-valor">
              <span [style.color]="color" class="main" itemprop="price">R$ {{valorPromocao1}},{{valorPromocao2}}</span> 
            </div>
          </div>
        </div>  
      </div>
      <div class="valor-number content tariff" *ngIf="valor == 0">
        <div class="box">
          <span class="a-partir">a partir de:</span>
        </div>
        <div class="box box-valor">
          <span [style.color]="color" class="consulte" itemprop="price">CONSULTE!</span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'servico'" class="valor default default-servico {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="valor-number" *ngIf="valor > 0">
        <div class="content" *ngIf="!servico.promocao">
          <div class="box">
            <span class="a-partir">a partir de:</span>
          </div>
          <div class="box box-valor content-total">
            <div class="total">
              <span class="cifrao" itemprop="priceCurrency">R$</span>
              <strong [style.color]="color" class="main" itemprop="price">{{valor1}}</strong> 
              <span>,{{valor2}}</span>
            </div>
          </div>
        </div>  
        <div class="content _promocao" *ngIf="servico.promocao"> 
          <div class="box">
            <div class="valor-promocao">
              <span>{{'De' | translate}} <strong>R$ {{valor}}</strong></span><br>
              <span>{{'por' | translate}}</span>
            </div>
            <div class="box-valor">
              <span itemprop="priceCurrency">R$</span>
              <span [style.color]="color" class="main" itemprop="price">{{valorPromocao1}}</span> 
              <span>,{{valorPromocao2}}</span>
            </div>
          </div>
        </div>  
      </div>
      <div class="valor-number content" *ngIf="valor == 0">
        <div class="box">
          <span class="a-partir">{{'a partir de' | trabslate}}:</span>
        </div>
        <div class="box">
          <span [style.color]="color" class="consulte" itemprop="price">{{'CONSULTE' | translate}}!</span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'cart'" class="valor valor-cart {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <span class="a-partir">{{'Valor' | translate}}</span>  
      <div class="content box-valor">    
        <span>R$</span>
        <span [style.color]="color" class='main'>{{valor1}}</span> 
        <span>,{{valor2}}</span>
      </div>
    </div>
</div>  
  