import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { MaxPipe } from 'src/app/pipes/max.pipe';
import { FormatDuracaoPipe } from 'src/app/pipes/format-duracao.pipe';
import { DateFormatTextPipe } from 'src/app/pipes/date-format-text.pipe';
import { TipoPassageiroDescPipe } from 'src/app/pipes/tipo-passageiro-desc.pipe';
import { FormatDiasPipe } from '../../pipes/format-dias.pipe';
import { GetTarifaMinimaListaPipe } from 'src/app/pipes/get-tarifa-minima-lista.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { FormatValorManagerPipe } from 'src/app/pipes/format-valor-manager.pipe';
import { DateMesTextPipe } from 'src/app/pipes/date-mes-text.pipe';
import { DateDiaTextPipe } from 'src/app/pipes/date-dia-text.pipe';
import { FormatDateTextTwoPipe } from 'src/app/pipes/format-date-text-two.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    MaxPipe,
    DateFormatTextPipe,
    FormatDuracaoPipe,  
    TipoPassageiroDescPipe, 
    FormatDiasPipe,
    GetTarifaMinimaListaPipe,
    TranslatePipe,
    FormatValorManagerPipe,
    DateMesTextPipe,
    DateDiaTextPipe,
    FormatDateTextTwoPipe
  ],
  exports: [
    SafeHtmlPipe,
    MaxPipe,
    FormatDuracaoPipe,
    DateFormatTextPipe,
    TipoPassageiroDescPipe,
    FormatDiasPipe,
    GetTarifaMinimaListaPipe,
    TranslatePipe,
    FormatValorManagerPipe,
    DateMesTextPipe,
    DateDiaTextPipe,
    FormatDateTextTwoPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule{}
