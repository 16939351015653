<div class="modulo modulo-fale-conosco" [ngSwitch]="template">
  <div class="default" *ngSwitchCase="null">
    <div class="container">
      <div class="modulo-titulo wow pulse" data-wow-iteration="3" data-wow-duration="1s" data-wow-delay="0.5s" *ngIf="data.mostrar_titulo">
        <h2 [innerHtml]="data.titulo"></h2>
        <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
      </div>
      <div class="modulo-content">
        <form class="form-custom" autocomplete="off" (submit)="send()">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Nome</label>
                <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
              </div>  
              <div class="form-group">
                <label>E-mail</label>
                <input type="text" [(ngModel)]="dataItem.email" name="email" />
              </div> 
              <div class="form-group form-group-telefone">
                <label>Telefone</label>
                <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" mask="(99) 99999-9999" />
              </div> 
              <div class="form-group">
                <input-date-range [clear]="clearDate" classCustom="default-fale-conosco" label="Informe um Período:" (change)="changeDate($event)"></input-date-range>
              </div> 
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Descreva o que você precisa:</label>
                <textarea [(ngModel)]="dataItem.mensagem" name="mensagem"></textarea>
              </div> 
              <div class="form-group">
                <button type="submit" class="btn-one">    
                  <span>Solicitar ajuda</span>
                </button>  
              </div>
            </div>
          </div>
        </form>  
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'two'" class="template-two">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <span class="titulo">
            Vamos montar <br>algo personalizado? <br> Nós te auxiliamos!
          </span>
        </div> 
        <div class="col-md-8">
          <form class="form" (submit)="send()" autocomplete="off">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.nome" placeholder="Nome*" name="nome" />
                </div>
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.email" placeholder="E-mail*" name="email" />
                </div>  
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.telefone" placeholder="Telefone*" name="telefone" phoneMask />
                </div>  
              </div>
              <div class="col-md-6">
                <div class="area-click" [hidden]="!showArea" (click)="setShowArea()">
                  <span class="area-click-titulo">
                    Descreva o que você precisa
                  </span>  
                  <span class="area-click-info">
                    (importante mencionar o período da viagem e quantidade de pessoas, assim podemos agilizar seu atendimento!)
                  </span>  
                </div>
                <textarea #textarea [hidden]="showArea" [(ngModel)]="dataItem.mensagem" name="mensagem" rows="10"></textarea>
                <div class="container-button">
                  <button type="submit" [disabled]="loader">
                    <span *ngIf="!loader">Solicitar Ajuda</span>
                    <span *ngIf="loader">Enviando...</span>
                    <img src="/images/icons/solicitar-ajuda.svg" alt="Solicitar Ajuda" width="25px" />
                  </button>  
                </div> 
                <div class="form-group">
                  <span class="subscribe-message" [innerHtml]="subscribeMessage"></span>
                </div> 
              </div>
            </div>
          </form>  
        </div>  
      </div>  
    </div>
  </div> 
  <div class="default-one" *ngSwitchCase="'menu-ajuda'">
    <div class="box">
      <button type="button">
        <i class="far fa-envelope"></i>
        <span>enviar mensagem</span>
      </button>
    </div>  
  </div>  
</div>
<loader *ngIf="loader"></loader>
