<div [ngSwitch]="template">
    <div class="btn-login" *ngSwitchCase="null">
        <div class="item item-user" *ngIf="usuario == null">
            <a href="javascript::void();" (click)="_open()" title="Usuário de Compras">
                <div class="icon">
                    <i class="fa-regular fa-user"></i>
                </div>
                <div class="text">
                    <span>Acessar</span>
                    <strong>Minha Conta</strong>
                </div>
            </a> 
        </div>
        <div class="content content-usuario" (click)="toRoute('/minha-conta/perfil')" *ngIf="usuario !== null">
            <a href="javascript::void();" class="foto btn-header-action">
                <div class="item">
                    Olá,<br>
                    <strong>{{nome}}</strong>
                </div>
            </a>
        </div>       
    </div>
    <div class="btn-login two" *ngSwitchCase="'two'"> 
        <button type="button" (click)="_open()">
            <span>Já tenho uma conta</span>
        </button> 
    </div>
    <div class="btn-login three" *ngSwitchCase="'three'">   
        <button type="button" (click)="_open(1)">
            <span>Clique Aqui</span>
        </button> 
    </div>     
</div>        
