import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'dateDiaText'
})
export class DateDiaTextPipe implements PipeTransform {

  constructor(
    private date: DateService
  ){

  }
  /**
   * 
   * @param value 
   * @param args 
   * 
   */
  transform(value:any, ...args: unknown[]):any{
    
    return value.substring(0,2);

  }

}
