<div class="module menu-footer-partial">
    <div class="module-title">
        <h3>Links Úteis</h3>
    </div>    
    <div class="module-content">
        <ul>
            <li>
                <a routerLink="/contato" title="Fale Conosco">
                    Fale Conosco
                </a>    
            </li>  
            <li>
                <a routerLink="/sobre" title="Quem Somos">
                    Quem Somos
                </a>    
            </li>  
            <li>
                <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade">
                    Políticas de Privacidade
                </a>    
            </li> 
            <li>
                <a routerLink="/politicas-de-cancelamento" title="Políticas de Cancelamento">
                    Políticas de Cancelamento
                </a>    
            </li> 
            <li>
                <a routerLink="/duvidas-frequentes" title="Dúvidas Frequentes">
                    Dúvidas Frequentes
                </a>    
            </li>     
        </ul> 
    </div>   
</div>    