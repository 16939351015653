<header class="header template-default">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-3 logo"> 
                    <modulo posicao="logo"></modulo>  
                </div>
                <div class="col-lg-4 col-md-4 content-box-pesquisa">
                    <box-form-pesquisa></box-form-pesquisa>  
                </div>  
                <div class="col-lg-4 col-md-4 col-9">   
                    <div class="content-action">
                        <div class="item item-cart">  
                            <btn-cart></btn-cart>   
                        </div>
                        <div class="item item-user"> 
                            <btn-usuario-login [usuarioService]="usuarioService"></btn-usuario-login>
                        </div> 
                    </div>    
                </div>
            </div>    
        </div>     
    </div>
    <div class="content-menu-mobile">
        <partial-menu classCustom="menu-mobile" class="container-menu-mobile"></partial-menu>
    </div>  
    <div class="content-menu">
        <div class="container">
            <partial-menu></partial-menu> 
        </div>    
    </div>  
    <div class="content-box-pesquisa-mobile">
        <box-form-pesquisa></box-form-pesquisa>  
    </div>  
</header>  
<sidebar-menu [showMenu]="showMenu"></sidebar-menu>   