<div class="modulo banner-slider-slick animated fadeIn">
  <div class="navigate">
    <div class="navigate-item prev" (click)="prevSlide()">
      <div class="content">
        <i class="material-icons">navigate_before</i>
      </div>
      <div class="bg"></div>
    </div> 
    <div class="navigate-item next" (click)="nextSlide()">
      <div class="content">
        <i class="material-icons">navigate_next</i>
      </div>
      <div class="bg"></div>
    </div>    
  </div> 
  <div #banner class="banner-slider-slick-content">
    <div *ngFor="let i of data.banners;let y = index">
      <div class="item slider-item" *ngIf="!i.link_ativo">
        <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
      </div>  
      <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
        <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a> 
        <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>   
      </div>
    </div>
  </div>
  <div class="navigate-bottom"> 
    <div class="container">
        <div class="navigate-bottom-content">
            <div class="item" *ngFor="let m of data.banners;let i=index;let l=last" (click)="selectBanner(i)" [ngClass]="{'last':l}">
                <span>{{m.titulo}}</span>
            </div> 
            <div class="bg"></div>
        </div>
    </div>   
  </div>
</div>


