<div class="cart-total content-action" (click)="_onClick()">
    <div class="item item-cart">
        <div class="box">
            <a routerLink="/carrinho" title="Carrinho de Compras">
                <i class="fa-solid fa-cart-shopping"></i>
            </a> 
        </div>  
        <div class="box box-text">
            <span>Meu</span>
            <strong>Carrinho ({{qtd}})</strong>
        </div>
    </div>    
</div>   
