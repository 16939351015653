<div class="box-form-pesquisa">
      <div class="content">
            <form class="form form-custom" (submit)="_search()">
                  <div class="input-box">
                        <input type="text" [(ngModel)]="dataItem.termo" placeholder="Busque aqui o que você precisa" name="termo" />
                        <div class="icon" (click)="_search()">
                              <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                  </div>
            </form>
      </div>      
</div>