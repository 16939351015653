import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'partial-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit{

  @Input("classCustom") classCustom = ""; 
  public openMenu                   = false;
  public categorias                 = [];
  @ViewChild("menu") menu:ElementRef;

  constructor(
    private router: Router,
    private data: DataService,
    private app: AppService
  ){
    this._onEvents();
  } 
  /**
   * 
   * Close
   * 
   */
  _close(){ 
    this.openMenu = false; 
  }
  /**
   * 
   * Open Menu
   * 
   */ 
  _open(){   
    this.menu.nativeElement.setAttribute("style",""); 
    this.openMenu = true;
  }
  /**
   * 
   * On Events
   * 
   */
  _onEvents(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        
      }
      
      if(event instanceof NavigationEnd) {

        this.openMenu = false;

      } 

    });    
    this.app.emitGetCategorias.emit(null);   
    this.app.emitSetCategorias.subscribe((data) => {

      this.categorias = data;     

    });
    
  }
  /**
   * 
   * Seta as categorias
   * 
   */
  setCategorias(){

    this.categorias = this.data.getCategoriasMenu();

  }
  
  /**
   * 
   * Inciliza as Funções
   * 
   */
  ngOnInit():void{
    this.setCategorias();

    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngAfterViewInit():void{
    
    if(this.classCustom == "menu-mobile"){
      this.menu.nativeElement.setAttribute("style","display:none;"); 
    }    
  
  }


}
