import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'box-form-pesquisa',
  templateUrl: './box-form-pesquisa.component.html',
  styleUrls: ['./box-form-pesquisa.component.scss']
})
export class BoxFormPesquisaComponent implements OnInit {

  public dataItem = {
    termo: ""
  }

  constructor(
    public router: Router
  ){}

  /**
   * 
   * Serch
   * 
   */
  _search(){

    this.router.navigate(["/buscar"],{queryParams:this.dataItem});

    return false;

  }
  /**
   * 
   * Init
   * 
   */ 
  ngOnInit():void{}
}
