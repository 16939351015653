import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { GtagService } from 'src/app/services/gtag.service';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'btn-google-login', 
  templateUrl: './btn-google-login.component.html',
  styleUrls: ['./btn-google-login.component.scss']
})
export class BtnGoogleLoginComponent implements OnInit {
  
  public usuario = null;
  public loader  = false; 
  @Input("usuarioService") usuarioService:any = null;

  constructor(
    private authService: SocialAuthService,
    private storage: StorageService,
    private api: ApiService,
    private app: AppService,
    private gtag: GtagService
  ){ }

  /**
   * 
   * Open
   * 
   */
  _open(){

    this.gtag.clickGoogle();  

      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user:any) => {

        if(user.provider == "GOOGLE"){ 
          
          this.usuario = {
            id: user.id,
            nome: user.name,
            email: user.email,
            primeiro_nome: user.firstName,
            ultimo_nome: user.lastName,
            foto: user.photoUrl,
            provider: "GOOGLE"
          };
          this.loader = true;  
          this.api.loja().login_social(this.usuario).subscribe(response => {

            this.loader = false;

            if(response.status == 1){ 
              this.usuarioService.emitUsuarioLogin.emit(response.data); 
              this.storage.setUsuario(response.data);
            }else{

              this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");
  
            }

          },(response) => {
            
            this.loader = false;  
            this.app.info("Houve um erro. Tente novamente ou entre em contato com nossa equipe.","danger");

          });
          

        }

      },(response) => {

        if(response == "Login providers not ready yet. Are there errors on your console?"){
          this._open();
        } 
        
      

      });

  }
  /**
   * 
   * Ouvinte
   * 
   */
  onData(){

    /*this.authService.readyState.subscribe((user) => {

    },(error) => {

    });

    this.authService.authState.subscribe((user) => {

      alert(JSON.stringify(user)); 

    },(error) => {

    });*/

  } 
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    
  }

}
