import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'dateMesText'
})
export class DateMesTextPipe implements PipeTransform {

  constructor(
    private date: DateService
  ){

  }
  /**
   * 
   * Init
   * 
   */
  transform(value:any,...args: unknown[]):any{

    let data  = value.split("/");   
    let month = this.date.getMonthStringTwo(data[1]);

    return (month.substring(0,3)).toUpperCase();

  }

}
