import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'formatDateTextTwo'
})
export class FormatDateTextTwoPipe implements PipeTransform {

  constructor(private date: DateService){}
  
  transform(value:string,...args: unknown[]):any{
    
    let data  = value.split("-");
    let day   = data[2];
    let month = this.date.getMonthStringTwo(parseInt(data[1]));
    let year  = data[0];
    
    
    return day+" de "+month+" de "+year; 

  }

}
