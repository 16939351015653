<div class="btn-menu-content">
    <a href="javascript:void()" class="btn-menu-open fadeIn" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </a>
</div>  
<div class="btn-close wow fadeIn" data-wow-delay="0.5s" (click)="_close()" [ngClass]="{'show': openMenu}">
    <div class="content">
        <span class="material-icons">close</span>
    </div>
</div>     
<nav class="menu {{classCustom}}" #menu [ngClass]="{'show': openMenu,'hide':!openMenu}">
    <div class="menu-header">
        <img class="center" width="40px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/logo/logo-oma-glass.png" alt="Logo Oma Glas Turismo" />
    </div>    
    <ul>
        <li>
            <a routerLink="/" title="Costazul Turismo" routerLinkActive="active">   
                <span>Home</span>
            </a>    
        </li>
        <li *ngFor="let c of categorias">
            <a routerLink="/servicos/{{c.apelido}}" title="{{c.titulo}}" routerLinkActive="active">
                <span>{{c.titulo}}</span> 
            </a>    
        </li> 
        <li>
            <a routerLink="/sobre" title="Sobre a Oma Glas Turismo" routerLinkActive="active">   
                <span>Sobre a Oma Glas Turismo</span>
            </a>    
        </li> 
        <li>
            <a routerLink="/contato" title="Contato" routerLinkActive="active">   
                <span>Contato</span>
            </a>    
        </li>  
    </ul>    
</nav>
<div class="bg-overlay">
    <div class="overlay" [ngClass]="{'show': openMenu}"></div>  
</div>    
     
