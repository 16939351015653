<footer bg-1>
    <div class="topo">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <modulo posicao="logo-footer"></modulo>
                </div>  
                <div class="col-md-9">
                    
                </div>    
            </div> 
            <div class="linha" style="margin-top: 0;"></div>      
        </div>    
    </div>  
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <modulo posicao="endereco"></modulo>
                </div>
                <div class="col-md-3">  
                    <menu-footer-partial></menu-footer-partial>  
                </div>    
                <div class="col-md-3">
                    <modulo posicao="destinos-menu"></modulo>
                </div>
                <div class="col-md-3">   
                    <modulo posicao="contato" template="footer2"></modulo>
                    <modulo class="content-rede-social" posicao="rede-social" template="footer"></modulo> 
                </div>
            </div> 
        </div>
    </div>    
    <copyright></copyright>
</footer>        