import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaleConoscoComponent } from 'src/app/modulos/fale-conosco/fale-conosco.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { DirectivesModule } from '../../directives/directives/directives.module';
import { DmInputModule } from '../../form/dm-input/dm-input.module';
import { LoaderModule } from '../../loader/loader/loader.module';

@NgModule({
  declarations: [
    FaleConoscoComponent
  ],
  exports: [
    FaleConoscoComponent
  ],
  imports: [
    DmInputModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    DirectivesModule,
    LoaderModule
  ]
})
export class ModuloFaleConoscoModule { }
