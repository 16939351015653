import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;
declare var Splide:any;

@Component({
  selector: 'banner-splider',
  templateUrl: './banner-splide.component.html',
  styleUrls: ['./banner-splide.component.scss']
})
export class BannerSplideComponent implements OnInit,AfterViewInit {

  @Input("data")  data               = null; 
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null; 
  @ViewChild("banner") banner:ElementRef;
  @ViewChild("slide") slide:any;
  public banners                     = []; 
  public sliderElement:any           = null;
  public isMobile = false;

  constructor(
    private route: Router
  ){}

  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    this.sliderElement.Components.Controller.go(this.sliderElement.Components.Controller.getPrev());

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    this.sliderElement.Components.Controller.go(this.sliderElement.Components.Controller.getNext());
    
  }
  /**
   * 
   * Init splide
   * 
   */
   initSplide(){

    this.sliderElement = new Splide(this.slide.nativeElement, {
      type   : 'loop',
      perPage: 1,
      arrows: false, 
      autoplay: true
    }).mount(); 
    

  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    this.setMobile();

  }
  /**
   * 
   * Set mobile
   * 
   */
  setMobile(){

    let width = window.innerWidth;

    if(width < 700){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }

    this.setBanners();

  }
  /**
   * 
   * Set banners
   * 
   * 
   */
  setBanners(){

    let data = [];

    for (let index = 0;index < this.data.banners.length; index++) {

      if(this.isMobile && (this.data.banners[index].imagem_mobile !== null && this.data.banners[index].imagem_mobile !== "")){
        this.data.banners[index].foto = this.data.banners[index].imagem_mobile;
      }else{
        this.data.banners[index].foto = this.data.banners[index].imagem;
      }
      data.push(this.data.banners[index]); 
      
    }

    this.banners = data;

    setTimeout(() => {
      //this.initSplide();
    },10);

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){}
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    
    this.setMobile();
    setTimeout(() => {
      this.initSplide();
    },100);

  }

}
